import React from 'react'

export default function YedekParca() {
  return (
    <div>
  <div className="back_re">
    <div className="container">
      <div className="row">
        <div className="col-md-12 pt-3">
          <div className="title">
            <h2>YEDEK PARÇA</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* projects */}
  <div className="projects">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="titlepage">
          <span>Aşağıdaki linkten tüm stoklarımızı görebilirsiniz</span>
          <br></br>
          <a href='https://alperismakinalarihidrolik.sahibinden.com/'> <p>https://alperismakinalarihidrolik.sahibinden.com</p> </a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div id="proj" className="carousel slide projects_ban" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#proj" data-slide-to={0} className="active" />
            <li data-target="#proj" data-slide-to={1} />
            <li data-target="#proj" data-slide-to={2} />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container-fluid">
                <div className="carousel-caption relative3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/3-4 TON CUKUROVA_ROTARY GRUPLU_POCLINE_YURUYUS HIDROMOTORU_2.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>3-4 TON CUKUROVA ROTARY GRUPLU <br /> YURUYUS HIDROMOTOR</h3>
                          <p>3-4 TON CUKUROVA ROTARY GRUPLU POCLINE YURUYUS HIDROMOTORU</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/3-4 TON CUKUROVA_ROTARY GRUPLU_POCLINE_YURUYUS HIDROMOTORU.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>3-4 TON CUKUROVA ROTARY GRUPLU <br /> YURUYUS HIDROMOTOR</h3>
                          <p>3-4 TON CUKUROVA ROTARY GRUPLU POCLINE YURUYUS HIDROMOTORU</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/3-4 TON_YURUYUS HIDROMOTOR KAMI.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>3-4 TON YURUYUS <br /> HIDROMOTOR KAMI</h3>
                          <p>3-4 TON YURUYUS HIDROMOTOR</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid">
                <div className="carousel-caption relative3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/4 TON CUKUROVA_CERLI_HIDROMOTOR_2.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>4 TON CUKUROVA <br /> CERLI HIDROMOTOR</h3>
                          <p>4 TON CUKUROVA CERLI HIDROMOTOR</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/4 TON CUKUROVA_CERLI_HIDROMOTOR.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>4 TON CUKUROVA<br /> CERLI HIDROMOTOR</h3>
                          <p>4 TON CUKUROVA CERLI HIDROMOTOR</p>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/4 TON CUKUROVA_YURUYUS ROTARY GRUBU_2.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>4 TON CUKUROVA <br /> YURUYUS ROTARY GRUBU</h3>
                          <p>4 TON CUKUROVA YURUYUS ROTARY GRUBU</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid">
                <div className="carousel-caption relative3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/4 TON CUKUROVA_YURUYUS ROTARY GRUBU.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>4 TON CUKUROVA <br /> YURUYUS ROTARY GRUBU</h3>
                          <p>4 TON CUKUROVA YURUYUS ROTARY GRUBU</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/5 TON CUKUROVA_YURUYUS_HIDROMOTOR.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>5 TON CUKUROVA <br /> YURUYUS HIDROMOTOR</h3>
                          <p>5 TON CUKUROVA YURUYUS HIDROMOTOR</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/5 TON CUKUROVA_YURUYUS HIDROMOTOR_2.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>5 TON CUKUROVA<br />YURUYUS HIDROMOTOR</h3>
                          <p>5 TON CUKUROVA YURUYUS HIDROMOTOR</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid">
                <div className="carousel-caption relative3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/CUKUROVA_ZF SANZUMAN BEYNI.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>CUKUROVA ZF <br /> SANZUMAN BEYNI</h3>
                          <p>CUKUROVA ZF SANZUMAN BEYNI</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/CUKUROVA_ZF SANZUMAN KOMPLESI_2.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>CUKUROVA ZF <br /> SANZUMAN KOMPLESI</h3>
                          <p>CUKUROVA ZF SANZUMAN KOMPLESI</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/CUKUROVA_ZF SANZUMAN KOMPLESI.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>CUKUROVA ZF <br /> SANZUMAN KOMPLESI</h3>
                          <p>CUKUROVA ZF SANZUMAN KOMPLESI</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid">
                <div className="carousel-caption relative3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/HYUNDAIHDF 50_CUKUROVA 50PS_SANZIMAN - DIFERANSIYEL KOMPLE GRUP_GRAZIANO_2.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                          <h3>HYUNDAI HDF 50 CUKUROVA SANZIMAN<br /> DIFERANSIYEL KOMPLE GRUP GRAZIANO</h3>
                          <p>HYUNDAI HDF 50 CUKUROVA 50PS SANZIMAN - DIFERANSIYEL KOMPLE GRUP GRAZIANO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/HYUNDAIHDF 50_CUKUROVA 50PS_SANZIMAN - DIFERANSIYEL KOMPLE GRUP_GRAZIANO_3.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>HYUNDAI HDF 50 CUKUROVA SANZIMAN<br /> DIFERANSIYEL KOMPLE GRUP GRAZIANO</h3>
                          <p>HYUNDAI HDF 50 CUKUROVA 50PS SANZIMAN - DIFERANSIYEL KOMPLE GRUP GRAZIANO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/HYUNDAIHDF 50_CUKUROVA 50PS_SANZIMAN - DIFERANSIYEL KOMPLE GRUP_GRAZIANO_4.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>HYUNDAI HDF 50 CUKUROVA SANZIMAN<br /> DIFERANSIYEL KOMPLE GRUP GRAZIANO</h3>
                          <p>HYUNDAI HDF 50 CUKUROVA 50PS SANZIMAN - DIFERANSIYEL KOMPLE GRUP GRAZIANO</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid">
                <div className="carousel-caption relative3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/HYUNDAIHDF 50_CUKUROVA 50PS_SANZIMAN - DIFERANSIYEL KOMPLE GRUP_GRAZIANO_5.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>HYUNDAI HDF 50 CUKUROVA SANZIMAN<br /> DIFERANSIYEL KOMPLE GRUP GRAZIANO</h3>
                          <p>HYUNDAI HDF 50 CUKUROVA 50PS SANZIMAN - DIFERANSIYEL KOMPLE GRUP GRAZIANO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/HYUNDAIHDF 50_CUKUROVA 50PS_SANZIMAN - DIFERANSIYEL KOMPLE GRUP_GRAZIANO_6.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>HYUNDAI HDF 50 CUKUROVA SANZIMAN<br /> DIFERANSIYEL KOMPLE GRUP GRAZIANO</h3>
                          <p>HYUNDAI HDF 50 CUKUROVA 50PS SANZIMAN - DIFERANSIYEL KOMPLE GRUP GRAZIANO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project">
                        <div className="project_img">
                          <figure><img src="assets/images/yedek_parca/HYUNDAIHDF 50_CUKUROVA 50PS_SANZIMAN - DIFERANSIYEL KOMPLE GRUP_GRAZIANO.jpeg" alt="#" /></figure>
                        </div>
                        <div id="pro_ho" className="project_text">
                        <h3>HYUNDAI HDF 50 CUKUROVA SANZIMAN<br /> DIFERANSIYEL KOMPLE GRUP GRAZIANO</h3>
                          <p>HYUNDAI HDF 50 CUKUROVA 50PS SANZIMAN - DIFERANSIYEL KOMPLE GRUP GRAZIANO</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#proj" role="button" data-slide="prev">
              <i className="fa fa-arrow-left" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#proj" role="button" data-slide="next">
              <i className="fa fa-arrow-right" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
  );
}
