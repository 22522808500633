import Home from './components/Home';
import {Routes,Route, BrowserRouter } from 'react-router-dom';
import About from './components/About';
import Project from './components/Project';
import Contact from './components/Contact';
import NoPage from './components/NoPage';
import Header from './components/Header';
import Footer from './components/Footer';
import Onarim from './components/Onarim';
import YedekParca from './components/YedekParca';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
      <br></br>
    <Routes >
    <Route exact path="/" element={<Home/>} />
    <Route path="about"element={<About/>} />
    <Route path="project" element={<Project/>} />
    <Route path="contact" element={<Contact/>} />
    <Route path ="onarim" element={<Onarim/>}/>
    <Route path ="yedekparca" element={<YedekParca/>}/>
    <Route path="*" element={<NoPage />} />
  </Routes > 
     <Footer/>
  </BrowserRouter>
    </div>
  );
}

export default App;
