import React from 'react'
import Gallery from './Gallery';

const galleryImages = [
  {
    img: 'assets/images/3.jpg'
  },
  {
    img: "assets/images/2.jpg"
  },
  {
    img: "assets/images/kiralama/3.jpg"
  },
  {
    img: "assets/images/kiralama/kira1.jpeg"
  },
  {
    img: "assets/images/kiralama/kira2.jpg"
  },
  {
    img: "assets/images/kiralama/kira3.jpg"
  }
]


export default function Project() {
  return (
    <div>
  <div className="back_re">
    <div className="container">
      <div className="row">
        <div className="col-md-12 pt-3">
          <div className="title">
            <h2>FORKLIFT</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* projects */}
  <div className="projects">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="titlepage">
            <span>Forklift işlerini aşağıda görebilirsiniz.</span>
          </div>
        </div>
      </div>
      
<Gallery galleryImages={galleryImages}/>
    </div>
  </div>
</div>
  );
}
